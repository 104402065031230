import { IItem } from "../interfaces";
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    Divider,
} from "@mui/material";
import useRequest from "api/useRequest";
import { eventsGet } from "app/Events/actions";
import { IEventUpsale } from "app/Events/interfaces";
import trans from "helpers/trans";
import { FC, useMemo } from "react";
import { Modal, ModalTitle, ModalContent, RequestMessage, Loader } from "ui";
import usePermission from "hooks/usePermission";

interface IBookingsUpsalesModal {
    title: string;
    handleChangeSearch: Function;
    onClickUpsale: Function;
    onCloseModal: Function;
    id: number;
    selectedUpsales: (IItem | IEventUpsale)[];
    search: string;
}
const BookingsUpsalesModal: FC<IBookingsUpsalesModal> = ({
    title,
    onClickUpsale,
    onCloseModal,
    id,
    selectedUpsales,
}) => {
    const permission = usePermission("Events", "read");
    const lang = document.documentElement.lang;
    const { data, isPending, isLoading, isError, status, message } = useRequest(
        eventsGet(id, {
            params: {
                _with: ["subtranslations", "upsales.subtranslations"],
            },
        })
    );

    const upsalesList = useMemo(() => {
        if (!data?.data) return [];
        return data.data.upsales.filter(
            (item: IEventUpsale) =>
                !selectedUpsales.some((upsale: IItem | IEventUpsale) => {
                    if ("sellable_id" in upsale) {
                        return upsale.sellable_id === item.pivot.id;
                    } else if ("pivot" in upsale) {
                        return upsale.pivot.id === item.pivot.id;
                    } else return false;
                })
        );
    }, [data, selectedUpsales]);

    const renderContent = () => {
        if (isLoading || isPending) return <Loader />;
        if (isError)
            return (
                <RequestMessage
                    status={status}
                    message={message}
                    sx={{ mt: 2 }}
                />
            );
        if (upsalesList.length === 0)
            return (
                <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
                    {trans("event.upsales.modal.upsalesSelectModal.notFound")}
                </Typography>
            );
        if (!permission) return null;

        return (
            <List component="nav" sx={{ pt: 0 }}>
                {upsalesList.map((item: IEventUpsale) => {
                    return (
                        <Box key={`${item.id}`}>
                            <ListItem
                                button
                                onClick={() => onClickUpsale(item)}
                            >
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                    }}
                                >
                                    <ListItemText>
                                        {
                                            item.subtranslations.name[
                                                `lang_${lang}` as keyof typeof item.subtranslations.name
                                            ]
                                        }
                                    </ListItemText>
                                </Box>
                            </ListItem>
                            <Divider />
                        </Box>
                    );
                })}
            </List>
        );
    };

    return (
        <Modal
            open
            maxWidth="xs"
            fullWidth
            PaperProps={{ sx: { position: "fixed", top: 0 } }}
        >
            <ModalTitle onClose={() => onCloseModal()}>{title}</ModalTitle>
            <ModalContent sx={{ pt: 0, px: 2 }}>{renderContent()}</ModalContent>
        </Modal>
    );
};

export default BookingsUpsalesModal;
